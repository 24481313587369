import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Drawer, Input, Form, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Search from './Search'
import CompanyDetail from '@/pages/CompanyManagement/Detail'
import InviteDetail from './Detail'

import tableHeight from '@/utils/tableHeight'

import {
  recruit_getPageList,
  recruit_audit,
  recruit_close,
} from '@/services'

import utils from '@/utils';

const { Content } = Layout;

const App = () => {

  const [ companyDrawIsOpen, setCompanyDrawIsOpen ] = useState(false)
  const [ selectRowData, setSelectRowData ] = useState({})
  const [ inviteDrawIsOpen, setInviteDrawIsOpen ] = useState(false)
  const [ auditReasonShow, setAuditReasonShow ] = useState(false)
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "auditState": null,
    "companyId": null,
    "recruitJobCode": null,
    "recruitNo": null,
    "recruitTitle": null,
    "state": null,
    "pageNo": 1,
    "pageSize": 10,
  })
  const [ total, setTotal ] = useState(0)
  const [ form ] = Form.useForm();
  const [ messageApi, contextHolder ] = message.useMessage()

  const getListData = () => {
    setSelectRowData({})
    recruit_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    })
  }

  useEffect(() => {
    getListData()
  }, [params])

  const showCompanyDetail = (record) => {
    return () => {
      setCompanyDrawIsOpen(true)
    }
  }
  const showInviteDetail = (record) => {
    return () => {
      console.log(record)
      setInviteDrawIsOpen(true)
    }
  }

  const onDrawClose = () => {
    setCompanyDrawIsOpen(false)
    setInviteDrawIsOpen(false)
  }

  const setAudit = (stateValue) => {
    return (e) => {
    }
  }

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }
  const search = (data) => {
    let newParams = {
      ...data,
      pageNo: 1,
      pageSize: 10
    };
    setParams(newParams)
  }
  const reset = () => {
    setParams({
      "auditState": null,
      "companyId": null,
      "recruitJobCode": null,
      "recruitNo": null,
      "recruitTitle": null,
      "state": null,
      "pageNo": 1,
      "pageSize": 10,
    })
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '审核状态', dataIndex: 'auditState', width: 120, render: (text) => {
      return ['待审核','审核通过','审核未通过'][text]
    }},
    {title: '状态', dataIndex: 'state', width: 120, render: (text) => {
      return ['招聘中', '招聘中', '关闭'][text]
    }},
    {title: '招聘编号', dataIndex: 'recruitNo', width: 120},
    {title: '招聘标题', dataIndex: 'recruitTitle', width: 220, render: (text, record) => {
      return <span className='link' onClick={showInviteDetail(record)}>{text}</span>
    }},
    {title: '招聘企业', dataIndex: 'companyName', width: 220, render: (text, record) => {
      return <span className='link' onClick={showCompanyDetail(record)}>{text}</span>
    }},
    {title: '招聘岗位', dataIndex: 'recruitJobName', width: 120},
    {title: '薪资', dataIndex: 'wageName', width: 120},
    {title: '工作地', dataIndex: 'contactPhone', width: 220, render: (text, record) => {
      return record.hopeAreaDTOList ? utils.computeAreaValue(record.hopeAreaDTOList).desc.map((item, index) => {
        return (
          <div className="addr-item" key={index}>{item}</div>
        )
      }) : record.workplaceProvinceName == '全部' ? '地点不限' : [
        record.workplaceProvinceName,
        record.workplaceCityName,
        record.workplaceCountyName
      ].filter(item => item !== '全部').join(' ')
    }},
    {title: '招聘人', dataIndex: 'publisherName', width: 120},
    {title: '发布时间', dataIndex: 'createTime', width: 180},
    {title: '审核人', dataIndex: 'auditName', width: 180},
    {title: '审核时间', dataIndex: 'auditTime', width: 180},
    {title: '操作人', dataIndex: 'updateUserName', width: 180},
    {title: '操作时间', dataIndex: 'updateTime', width: 180},
  ]

  const setAuditPass = () => {
    recruit_audit({
      id: selectRowData.id,
      auditState: 1,
      nopassReason: '',
    }).then(res => {
      messageApi.open({type: 'success', content: '设置成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const setAuditNoPass = () => {
    form.resetFields()
    setAuditReasonShow(true)
  }
  const auditReasonOK = () => {
    form.validateFields().then(values => {
      recruit_audit({
        ...values,
        id: selectRowData.id,
        auditState: 2
      }).then(res => {
        messageApi.open({type: 'success', content: '设置成功'})
        setAuditReasonShow(false)
        getListData()
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    })
  }
  const auditReasonCancel = () => {
    setAuditReasonShow(false)
  }

  const closeInvite = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要关闭该条招聘信息吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        recruit_close({
          "id": selectRowData.id,
        }).then(res => {
          messageApi.open({type: 'success', content: '关闭成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  return (
    <Layout className='list-page'>
      <Space className=''>
        <Button disabled={!selectRowData.id} onClick={setAuditPass}>审核通过</Button>
        <Button disabled={!selectRowData.id} onClick={setAuditNoPass}>审核不过</Button>
        <Button disabled={!selectRowData.id} onClick={closeInvite}>关闭招聘</Button>
      </Space>
      <Search search={search} reset={reset}/>
      <Content>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight() }}
          columns={columns}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
        />
      </Content>
      <Drawer title='企业详情' placement='right' onClose={onDrawClose} open={companyDrawIsOpen}>
        <CompanyDetail id={selectRowData.companyId}/>
      </Drawer>
      <Drawer title='招聘详情' placement='right' onClose={onDrawClose} open={inviteDrawIsOpen}>
        <InviteDetail {...selectRowData}/>
      </Drawer>
      <Modal title="审核不过" open={auditReasonShow} onOk={auditReasonOK} onCancel={auditReasonCancel}>
        <Form
          form={form}
        >
          <Form.Item label='原因' name={'nopassReason'} rules={[{required: true, message: '请输入审核不过原因'}]}>
            <Input placeholder='审核不过原因'/>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  )
}
export default App
