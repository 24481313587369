import React from 'react'
import { Form, Input } from 'antd'

function Add(props) {

  return (
    <div>
      <Form.Item name='id' hidden><Input /></Form.Item>
      <Form.Item label='字典编码' name='dicCode' rules={[{ required: true, message: '请输入字典编码' }]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='字典名称' name='dicName' rules={[{ required: true, message: '请输入字典名称' }]}>
        <Input placeholder='请输入'/>
      </Form.Item>
    </div>
  )
}

export default Add
