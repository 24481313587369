import { Navigate } from 'react-router-dom'

import Home from '../pages/Home'
import Login from '../pages/Login'

import routes from './routes'

export default [
  {
    path: '/Login',
    element: <Login />,
    auth: false,
  }, {
    path: '/',
    element: <Home />,
    auth: true,
    child: routes
  },
]