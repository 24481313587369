import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Form, Drawer, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Add from './Add'
import tableHeight from '@/utils/tableHeight'
import {
  adminUser_getPageList,
  role_getPageList,
  staff_getPageList,
  adminUser_save,
  adminUser_update,
  adminUser_updateState,
  adminUser_delete,
  adminUser_resetPassword
} from '@/services'

const { Content } = Layout;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const App = () => {

  const [ selectRowData, setSelectRowData ] = useState({})
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "pageNo": 1,
    "pageSize": 10,
  })
  const [ total, setTotal ] = useState(0)
  const [ addShow, setAddShow ] = useState(false)
  const [ itemEditShow, setItemEditShow ] = useState(false)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ messageApi, contextHolder ] = message.useMessage();
  const [ form ] = Form.useForm()
  const [ memberListData, setMemberListData ] = useState([])
  const [ roleListData, setRoleListData ] = useState([])

  const showDetail = (record) => {
    return () => {
      console.log(record)
    }
  }

  const getListData = () => {
    setSelectRowData({})
    adminUser_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }

  useEffect(() => {
    getListData()
  }, [params])

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }


  const getMemberListData = () => {
    staff_getPageList({
      pageNo: 1,
      pageSize: 200
    }).then(res => {
      setMemberListData(res.data.info || [])
    })
  }

  const getRoleListData = () => {
    role_getPageList({
      pageNo: 1,
      pageSize: 200
    }).then(res => {
      setRoleListData(res.data.info || [])
    })
  }

  useEffect(() => {
    getMemberListData();
    getRoleListData();
  }, [])

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '状态', dataIndex: 'state', width: 120, render: (text) => {
      return ['初始','启用','停用'][text]
    }},
    {title: '用户编号', dataIndex: 'userNo', width: 140},
    {title: '用户（登录）名称', dataIndex: 'userName', width: 180, render: (text, record) => {
      return <span onClick={showDetail(record)}>{text}</span>
    }},
    {title: '姓名', dataIndex: 'staffName', width: 120, render: (text, record) => {
      return <span onClick={showDetail(record)}>{text}</span>
    }},
    {title: '性别', dataIndex: 'gender', width: 120, render: (text) => {
      return ['女','男'][text]
    }},
    {title: '手机号', dataIndex: 'phone', width: 120},
    {title: '用户角色', dataIndex: 'roleId', width: 180},
    {title: '创建人', dataIndex: 'createUserName', width: 120},
    {title: '创建时间', dataIndex: 'createTime', width: 180},
    {title: '最后修改人', dataIndex: 'updateUserName', width: 120},
    {title: '最后修改时间', dataIndex: 'updateTime', width: 180},
  ]

  const openAddDraw = () => {
    form.resetFields()
    setAddShow(true)
  }
  const onAddClose = () => {
    setAddShow(false)
  }
  const addUserSubmit = (values) => {
    form.validateFields().then(values => {
      let func = isEdit ? adminUser_update : adminUser_save
      func(values).then(res => {
        if(res.data.info) {
          Modal.info({
            title: '温馨提示',
            content: (
              <>
                <div>新增成功，新用户初始密码为：</div>
                <div style={{
                  fontSize: '32px',
                  fontWeight: 'bold',
                  color: '#322F9F'
                }}>{res.data.info}</div>
              </>
            ),
            okText: '知道了',
          })
        } else {
          messageApi.open({type: 'success', content: isEdit ? '修改成功' : '新增成功'})
        }
        setAddShow(false)
        pageChange(1)
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    })
  }

  const memberChange = (e) => {
    console.log(e)
    form.setFieldsValue({
      staffId: e.value
    })
  }
  const roleChange = (e) => {
    console.log(e)
    form.setFieldsValue({
      roleId: e.value
    })
  }
  const editUser = () => {
    form.setFieldsValue(selectRowData);
    setAddShow(true)
  }
  const deleteUser = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要删除该用户吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        adminUser_delete({
          "id": selectRowData.id,
        }).then(res => {
          messageApi.open({type: 'success', content: '删除成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }
  const setOn = () => {
    adminUser_updateState({
      "id": selectRowData.id,
      "state": 1
    }).then(res => {
      messageApi.open({type: 'success', content: '启用成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const setOff = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要停用该用户吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        adminUser_updateState({
          "id": selectRowData.id,
          "state": 2
        }).then(res => {
          messageApi.open({type: 'success', content: '停用成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }
  const resetPwd = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要重置该用户的密码吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        adminUser_resetPassword({
          "id": selectRowData.id,
        }).then(res => {
          if(res.data.info) {
            Modal.info({
              title: '温馨提示',
              content: (
                <>
                  <div>新增成功，新用户初始密码为：</div>
                  <div style={{
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: '#322F9F'
                  }}>{res.data.info}</div>
                </>
              ),
              okText: '知道了',
            })
          } else {
            Modal.info({
              title: '温馨提示',
              content: (
                <>
                  <div>重置密码成功，新密码为：</div>
                  <div style={{
                    fontSize: '32px',
                    fontWeight: 'bold',
                    color: '#322F9F'
                  }}>{res.data.info}</div>
                </>
              ),
              okText: '知道了',
            })
          }
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space className=''>
        <Button onClick={openAddDraw}>新增</Button>
        <Button disabled={!selectRowData.id} onClick={editUser}>修改</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={deleteUser}>删除</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={setOn}>启用</Button>
        <Button disabled={!selectRowData.id || selectRowData.state != 1} onClick={setOff}>停用</Button>
        <Button disabled={!selectRowData.id} onClick={resetPwd}>重置密码</Button>
      </Space>
      <Content style={{marginTop: '10px'}}>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title='用户新增' placement='right' onClose={onAddClose} open={addShow}>
        <Form
          form={form}
          {...formItemLayout} 
          onFinish={addUserSubmit}
        >
          <Add
            memberListData={memberListData}
            roleListData={roleListData}
            memberChange={memberChange}
            roleChange={roleChange}
          />
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 18,
            }}
          >
            <Space>
              <Button type='primary' htmlType="submit">确定</Button>
              <Button onClick={onAddClose}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </Layout>
  )
}
export default App
