import React from 'react'
import { Descriptions } from 'antd'

import utils from '@/utils';

const Detail = (props) => {
  return (
    <div>
      <Descriptions title={props.recruitTitle} column={1}>
        <Descriptions.Item label="招聘编号">{props.recruitNo}</Descriptions.Item>
        <Descriptions.Item label="招聘企业">{props.companyName}</Descriptions.Item>
        <Descriptions.Item label="招聘岗位">{props.recruitJobName}</Descriptions.Item>
        <Descriptions.Item label="招聘搜索关键词">{props.searchKeyword}</Descriptions.Item>
        <Descriptions.Item label="工作地">
          {
            props.hopeAreaDTOList ? utils.computeAreaValue(props.hopeAreaDTOList, '').desc.map((item, index) => {
              return (
                <div className="addr-item" key={index}>【{item}】</div>
              )
            }) : props.workplaceProvinceName == '全部' ? '地点不限' : [
              props.workplaceProvinceName,
              props.workplaceCityName,
              props.workplaceCountyName
            ].filter(item => item !== '全部').join(' ')
          }
        </Descriptions.Item>
        <Descriptions.Item label="招聘人数">{props.recruitNum}</Descriptions.Item>
        <Descriptions.Item label="薪资范围">{props.wageName} {props.wageMonth ? `${props.wageMonth}薪` : ''}</Descriptions.Item>
        <Descriptions.Item label="其它福利">{props.welfare}</Descriptions.Item>
        <Descriptions.Item label="招聘人">{props.publisherName}</Descriptions.Item>
        <Descriptions.Item label="岗位职责">
          <div dangerouslySetInnerHTML={{__html: props.jobResponsibilities}}></div>
        </Descriptions.Item>
        <Descriptions.Item label="任职资格">
          <div dangerouslySetInnerHTML={{__html: props.jobQualification}}></div>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default Detail
