import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Drawer, Modal, Form, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import CompanyDetail from '@/pages/CompanyManagement/Detail'
import AdvDetail from './Detail'
import Add from './Add'

import tableHeight from '@/utils/tableHeight'

import {
  advertInfo_getPageList,
  advertInfo_add,
  advertInfo_delete,
  advertInfo_update,
  advertInfo_updateState,
} from '@/services'

const { Content } = Layout;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const pageSize = 10

const App = () => {

  const [ messageApi, contextHolder ] = message.useMessage();
  const [ selectRowData, setSelectRowData ] = useState({})
  const [ companyDetailShow, setCompanyDetailShow ] = useState(false)
  const [ advAddShow, setAdvAddShow ] = useState(false)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ advDetailShow, setAdvDetailShow ] = useState(false)
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "pageNo": 1,
    "pageSize": 10,
  })
  const [ total, setTotal ] = useState(0)
  const [ form ] = Form.useForm();
  const [ fileList, setFileList ] = useState([
    // {
    //   uid: '-1',
    //   name: 'image.png',
    //   status: 'done',
    //   url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
    // },
  ]);
  const [ detailFileList, setDetailFileList ] = useState([])
  

  const onUploadChange = ({file, fileList}) => {
    console.log(fileList)
    setFileList(fileList);
    if(fileList.length > 0 && file.status == 'done') {
      form.setFieldsValue({
        advertImgUrl: fileList[0].response.info
      })
    }
  };
  const onDetailUploadChange = ({file, fileList}) => {
    console.log(fileList)
    setDetailFileList(fileList);
    if(fileList.length > 0 && file.status == 'done') {
      form.setFieldsValue({
        detailImgUrl: fileList[0].response.info
      })
    }
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const onDetailPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const showCompanyDetail = (record) => {
    return () => {
      console.log(record)
      setCompanyDetailShow(true)
    }
  }
  const showAdvDetail = (record) => {
    return () => {
      setAdvDetailShow(true)
    }
  }

  const getListData = () => {
    setSelectRowData({})
    advertInfo_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }

  const advAdd = () => {
    setFileList([])
    setDetailFileList([])
    form.resetFields()
    setIsEdit(false)
    setAdvAddShow(true)
  }
  const advEdit = () => {
    console.log(selectRowData)
    form.setFieldsValue(selectRowData)
    setFileList([{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: selectRowData.advertImgUrl
    }])
    setDetailFileList([{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: selectRowData.detailImgUrl
    }])
    setIsEdit(true)
    setAdvAddShow(true)
  }
  const handleAddOk = () => {
    form.validateFields().then(values => {
      console.log(values)
      let fun = isEdit ? advertInfo_update : advertInfo_add
      fun(values).then(res => {
        messageApi.open({type: 'success', content: isEdit ? '修改成功' : '新增成功'})
        isEdit ? getListData() : pageChange(1)
        setAdvAddShow(false)
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    }).catch(err => {
      console.log('errerrerr', err)
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const handleAddCancel = () => {
    setAdvAddShow(false)
  }

  const onCompanyDetailClose = () => {
    setCompanyDetailShow(false)
  }
  const onAdvDetailClose = () => {
    setAdvDetailShow(false)
  }

  

  useEffect(() => {
    getListData()
  }, [params])

  const advDelete = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认删除该条广告吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        advertInfo_delete({
          id: selectRowData.id
        }).then(res => {
          messageApi.open({type: 'success', content: '删除成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  const advPublish = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要发布该条广告吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        advertInfo_updateState({
          "id": selectRowData.id,
          "state": 1
        }).then(res => {
          messageApi.open({type: 'success', content: '发布成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  const advStop = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要停播该条广告吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        advertInfo_updateState({
          "id": selectRowData.id,
          "state": 2
        }).then(res => {
          messageApi.open({type: 'success', content: '停播成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '状态', dataIndex: 'state', width: 120, render: (text) => {
      return ['未发布', '播放中', '停播'][text]
    }},
    {title: '广告编号', dataIndex: 'advertNo', width: 160},
    {title: '广告名称', dataIndex: 'advertName', width: 220, render: (text, record) => {
      return <span className='link' onClick={showAdvDetail(record)}>{text}</span>
    }},
    {title: '企业', dataIndex: 'companyName', width: 120, render: (text, record) => {
      return <span className='link' onClick={showCompanyDetail(record)}>{text}</span>
    }},
    {title: '创建时间', dataIndex: 'createTime', width: 180},
    {title: '更新时间', dataIndex: 'updateTime', width: 180},
  ]

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space className=''>
        <Button onClick={advAdd}>新增</Button>
        <Button disabled={!selectRowData.id} onClick={advEdit}>修改</Button>
        <Button disabled={!selectRowData.id} onClick={advDelete}>删除</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={advPublish}>发布</Button>
        <Button disabled={!selectRowData.id || selectRowData.state != 1} onClick={advStop}>停播</Button>
      </Space>
      <Content style={{marginTop: '10px'}}>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: pageSize,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title='企业详情' placement='right' onClose={onCompanyDetailClose} open={companyDetailShow}>
        <CompanyDetail id={selectRowData.companyId}/>
      </Drawer>
      <Drawer title='广告详情' placement='right' onClose={onAdvDetailClose} open={advDetailShow}>
        <AdvDetail detail={selectRowData}/>
      </Drawer>
      <Drawer title={isEdit ? '修改广告' : '新增广告'} placement='right' onClose={handleAddCancel} open={advAddShow}>
        <Form
          form={form}
          {...formItemLayout}
          onFinish={handleAddOk}
        >
          <Add form={form}
            fileList={fileList}
            onUploadChange={onUploadChange}
            onPreview={onPreview}
            detailFileList={detailFileList}
            onDetailUploadChange={onDetailUploadChange}
            onDetailPreview={onDetailPreview}
          />
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 18,
            }}
          >
            <Space>
              <Button type='primary' htmlType="submit">确定</Button>
              <Button onClick={handleAddCancel}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </Layout>
  )
}
export default App
