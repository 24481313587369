import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Drawer, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Search from './Search'
import Detail from './Detail'

import tableHeight from '@/utils/tableHeight'

import {
  applyJob_getPageList,
  applyJob_close
} from '@/services'

import utils from '@/utils';

const { Content } = Layout;

const App = () => {

  const [ drawIsOpen, setDrawIsOpen ] = useState(false)
  const [ selectRowData, setSelectRowData ] = useState({})
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "applyJobNo": null,
    "jobCode": null,
    "state": null,
    "userId": null,
    "pageNo": 1,
    "pageSize": 10,
  })
  const [ total, setTotal ] = useState(0)
  const [ messageApi, contextHolder ] = message.useMessage()

  const getListData = () => {
    setSelectRowData({})
    applyJob_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    })
  }

  useEffect(() => {
    getListData()
  }, [params])

  const showDetail = (record) => {
    return () => {
      console.log(record)
      setDrawIsOpen(true)
    }
  }

  const onDrawClose = () => {
    setDrawIsOpen(false)
  }

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }
  const search = (data) => {
    let newParams = {
      ...data,
      pageNo: 1,
      pageSize: 10
    };
    setParams(newParams)
  }
  const reset = () => {
    setParams({
      "applyJobNo": null,
      "jobCode": null,
      "state": null,
      "userId": null,
      "pageNo": 1,
      "pageSize": 10,
    })
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '状态', dataIndex: 'state', width: 120, render: (text) => {
      return ['下架','上架','下架'][text]
    }},
    {title: '求职编号', dataIndex: 'applyJobNo', width: 220},
    {title: '求职标题', dataIndex: 'applyJobTitle', width: 220, render: (text, record) => {
      return <span className='link' onClick={showDetail(record)}>{text}</span>
    }},
    {title: '求职岗位', dataIndex: 'jobName', width: 200},
    {title: '求职人', dataIndex: 'nickName', width: 160, render: (text, record) => {
      return record.fullName || text
    }},
    {title: '求职人性别', dataIndex: 'gender', width: 120, render: (text) => {
      return text === 0 ? '女' : text == 1 ? '男' : '未设置'
    }},
    {title: '期望薪资', dataIndex: 'hopeWageName', width: 120},
    {title: '期望工作地', dataIndex: 'contactPhone', width: 220, render: (text, record) => {
      return record.hopeAreaDTOList ? utils.computeAreaValue(record.hopeAreaDTOList).desc.map((item, index) => {
        return (
          <div className="addr-item" key={index}>{item}</div>
        )
      }) : record.hopeWorkplaceProvinceName == '全部' ? '地点不限' : [
        record.hopeWorkplaceProvinceName,
        record.hopeWorkplaceCityName,
        record.hopeWorkplaceCountyName
      ].filter(item => item !== '全部').join(' ')
    }},
    {title: '发布时间', dataIndex: 'createTime', width: 180},
  ]

  const closeJob = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要关闭该条求职信息吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        applyJob_close({
          "id": selectRowData.id,
        }).then(res => {
          messageApi.open({type: 'success', content: '关闭成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space className=''>
        <Button disabled={!selectRowData.id} onClick={closeJob}>关闭求职</Button>
      </Space>
      <Search search={search} reset={reset}/>
      <Content>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title='求职详情' placement='right' onClose={onDrawClose} open={drawIsOpen}>
        <Detail {...selectRowData}/>
      </Drawer>
    </Layout>
  )
}
export default App
