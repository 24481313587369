import React from 'react'
import { Form, Input, Select, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

function Add(props) {
  return (
    <div>
      <Form.Item label='用户编号' name={'code'}>
        <Input disabled placeholder='系统自动生成'/>
      </Form.Item>
      <Form.Item label='用户名称' name={'userName'} rules={[
        {required: true, message: '请输入用户名称!'},
        {max: 20, message: '用户名称长度限制为：20', type: 'string'},
        {pattern: /^[a-zA-Z]+$/, message: '只能输入字母'}
      ]}>
        <Input placeholder='请输入' suffix={
          <Tooltip title="英文字母，长度20以内">
            <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}} />
          </Tooltip>
        }/>
      </Form.Item>
      <Form.Item label='人员' name={'staffId'} rules={[{required: true, message: '请选择人员!'}]}>
        <Select
          showSearch
          placeholder='请选择'
          options={props.memberListData}
          fieldNames={{
            label: 'staffName',
            value: 'id'
          }}
          labelInValue
          onChange={props.memberChange}
        />
      </Form.Item>
      <Form.Item label='用户角色' name={'roleId'} rules={[{required: true, message: '请选择用户角色!'}]}>
        <Select
          showSearch
          placeholder='请选择'
          options={props.roleListData}
          fieldNames={{
            label: 'roleName',
            value: 'id'
          }}
          labelInValue
          onChange={props.roleChange}
        />
      </Form.Item>
    </div>
  )
}

export default Add