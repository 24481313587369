import React from 'react'
import { ConfigProvider } from 'antd';
import { BrowserRouter, HashRouter } from 'react-router-dom'
import zhCN from 'antd/locale/zh_CN'

import './App.scss';

import AppRouter from '@/router/AppRouter'

import routes from '@/router'

const App = () => {
  console.log('REACT_APP_CC_ENV:', process.env.REACT_APP_CC_ENV)
  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: '#3836A8'
        }
      }}
    >
      <HashRouter>
        <AppRouter routes={routes} />
      </HashRouter>
    </ConfigProvider>
  )
}

export default App