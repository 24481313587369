import React from 'react'
import { Form, Input, Select } from 'antd'

import HzSearch from '@/components/HzSearch'

const stateData = [
  { label: '全部', value: null },
  { label: '启用', value: 1 },
  { label: '停用', value: 2 },
  { label: '草稿', value: 3 },
]

const genderData = [
  { label: '全部', value: null },
  { label: '男', value: 1 },
  { label: '女', value: 0 },
]

const Search = (props) => {

  const search = (values) => {
    props.search(values)
  }

  const reset = () => {
    props.reset()
  }

  return (
    <div className='Search'>
      <HzSearch
        initialValues={{
          "state": null,
          "userCode": null,
          "userName": null,
          "gender": null,
          "phone": null,
        }}
        search={search}
      >
        <Form.Item label="启停状态" name='state'>
          <Select style={{width: 100}} options={stateData} />
        </Form.Item>
        <Form.Item label="用户编号" name='userCode'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="用户名" name='userName'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="姓名" name='nickName'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="性别" name='gender'>
        <Select style={{width: 100}} options={genderData} />
        </Form.Item>
        <Form.Item label="手机号" name='phone'>
          <Input placeholder="请输入" />
        </Form.Item>
      </HzSearch>
    </div>
  )
}
export default Search
