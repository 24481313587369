import React from 'react'
import { Form, Input, Select } from 'antd'

import HzSearch from '@/components/HzSearch'

const stateData = [
  { label: '全部', value: null },
  { label: '新创建', value: 0 },
  { label: '启用', value: 1 },
  { label: '停用', value: 2 },
]

const enumFieldNames = {
  label: 'itemName',
  value: 'itemCode'
}

const Search = (props) => {

  const search = (values) => {
    props.search(values)
  }

  const reset = () => {
    props.reset()
  }

  return (
    <div className='Search'>
      <HzSearch
        initialValues={{
          "state": null,
          "jobCode": null,
          "staffNo": null,
          "staffName": null,
        }}
        search={search}
        reset={reset}
      >
        <Form.Item label="启停状态" name='state'>
          <Select style={{width: 100}} options={stateData} />
        </Form.Item>
        <Form.Item label="人员编号" name='staffNo'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="姓名" name='staffName'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="职位" name='jobCode'>
          <Select style={{width: '160px'}} placeholder='请选择' options={
            [{ itemCode: null, itemName: '全部' }].concat(props.enums.JOB_DUTY || [])
          } fieldNames={enumFieldNames}/>
        </Form.Item>
      </HzSearch>
    </div>
  )
}
export default Search
