import React, { useState, useEffect } from 'react'
import { LockOutlined, UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { Button, Image, Form, Input, message } from 'antd';
import { useNavigate } from "react-router-dom";

import logo from '@/resource/logo.png'
import './index.scss'
import {
  login,
  captcha
} from '@/services'

const Login = () => {

  const [ verifyImg, setVerifyImg ] = useState('')
  const [ form ] = Form.useForm()
  const navigate = useNavigate();
  const [ messageApi, contextHolder] = message.useMessage();
  const key = 'loginMsgKey';

  const onFinish = (values) => {
    messageApi.open({key, type: 'loading', content: '登录中……'})
    login(values).then(res => {
      messageApi.open({key, type: 'success', content: '登录成功！', duration: 1})
      localStorage.setItem('token', res.data.info.token)
      localStorage.setItem('userData', JSON.stringify(res.data.info.user))
      setTimeout(() => {
        navigate(`/`);
      }, 1200)
    }).catch(err => {
      messageApi.open({key, type: 'error', content: err.data.rspDesc})
    })
  }

  const getCaptcha = () => {
    captcha().then(res => {
      console.log(res)
      form.setFieldsValue({codeId: res.data.info.id})
      setVerifyImg('data:image/jpeg;base64,' + res.data.info.value)
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }

  useEffect(() => {
    if(!verifyImg) {
      getCaptcha()
    }
  })

  return (
    <div className='Login'>
      {contextHolder}
      <div className="container">
        <div className="main-area">
          <div className="welcome">欢迎登录</div>
          <div className="platform-name">汇智冷智慧冷链管理平台</div>
          <div className="platform-name-en">Intelligent cold chain management platform</div>
        </div>
        <div className="login-form">
          <div className="logo-area">
            <Image className='logo' src={logo} preview={false}></Image>
            <div className="text">冷链人才｜数智维保</div>
          </div>
          <Form
            form={form}
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {/* admintest   123456 */}
            <Form.Item name="codeId" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="userName" rules={[{required: true, message: '请输入用户名!'}]}>
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
            </Form.Item>
            <Form.Item name="password" rules={[{required: true, message: '请输入密码!'}]}>
              <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="密码"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name='verifyCode' noStyle rules={[{required: true, message: '请输入验证码!'}]}>
                <Input placeholder="验证码" style={{width: '120px', marginRight: '20px'}} />
              </Form.Item>
              <Image src={verifyImg} style={{width: '100px', height: '30px'}} preview={false} onClick={getCaptcha}></Image>
            </Form.Item>
            <Form.Item>
              <Button type="primary" block htmlType="submit" className="login-form-button">登录</Button>
            </Form.Item>
          </Form>
        </div>
        
      </div>
      <div className="footer">
        <div className="cpy-right">&copy; {new Date().getFullYear()} 河北真至人力资源服务有限公司</div>
        <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank' className="icp">冀ICP备2023001234号-1</a>
      </div>
    </div>
  )
}

export default Login