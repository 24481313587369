import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Form, Drawer, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Search from './Search'
import Add from './Add'
import tableHeight from '@/utils/tableHeight'

import {
  role_getPageList,
  role_delete,
  role_insert,
  role_update,
  role_updateState,
} from '@/services'

const { Content } = Layout;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const App = () => {

  const [ selectRowData, setSelectRowData ] = useState({})
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "state": null,
    "roleNo": null,
    "roleName": null,
    "pageNo": 1,
    "pageSize": 10,
  })
  const [ total, setTotal ] = useState(false)
  const [ addShow, setAddShow ] = useState(false)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ messageApi, contextHolder ] = message.useMessage();
  const [ form ] = Form.useForm()
  const [ selectedKeys, setSelectedKeys ] = useState([])

  const showDetail = (record) => {
    return () => {
      console.log(record)
    }
  }
  const getListData = () => {
    setSelectRowData({})
    role_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }

  useEffect(() => {
    getListData()
  }, [params])

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }

  const search = (data) => {
    let newParams = {
      ...data,
      pageNo: 1,
      pageSize: 10
    };
    setParams(newParams)
  }
  const reset = () => {
    setParams({
      "state": '',
      "roleNo": "",
      "roleName": "",
      "pageNo": 1,
      "pageSize": 10,
    })
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '状态', dataIndex: 'state', width: 120, render: (text) => {
      return ['创建','启用','停用'][text]
    }},
    {title: '角色编号', dataIndex: 'roleNo', width: 120},
    {title: '角色名称', dataIndex: 'roleName', width: 220, render: (text, record) => {
      return <span onClick={showDetail(record)}>{text}</span>
    }},
    {title: '创建人', dataIndex: 'creatorUserName', width: 120},
    {title: '创建时间', dataIndex: 'createTime', width: 180},
    {title: '最后修改人', dataIndex: 'updateUserName', width: 120},
    {title: '最后修改时间', dataIndex: 'updateTime', width: 180},
  ]
  const roleAdd = () => {
    setSelectedKeys([])
    form.resetFields()
    setIsEdit(false)
    setAddShow(true)
  }
  const menuChange = (selectedKeys) => {
    setSelectedKeys(selectedKeys)
    form.setFieldsValue({
      menuResourceIdList: selectedKeys
    })
  }
  const handleAddOk = () => {
    form.validateFields().then(values => {
      let func = isEdit ? role_update : role_insert
      func(values).then(res => {
        messageApi.open({type: 'success', content: isEdit ? '修改成功' : '新增成功'})
        setAddShow(false)
        pageChange(1)
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    })
  }
  const handleAddCancel = () => {
    setAddShow(false)
  }
  const roleEdit = () => {
    form.setFieldsValue(selectRowData);
    let list = selectRowData.menuResourceIdList;
    setSelectedKeys(list || [])
    setIsEdit(true)
    setAddShow(true)
  }
  const roleDelete = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要删除该角色吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        role_delete({
          "id": selectRowData.id,
        }).then(res => {
          messageApi.open({type: 'success', content: '删除成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }
  const roleOn = () => {
    role_updateState({
      "id": selectRowData.id,
      "state": 1
    }).then(res => {
      messageApi.open({type: 'success', content: '启用成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const roleOff = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要停用该角色吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        role_updateState({
          "id": selectRowData.id,
          "state": 2
        }).then(res => {
          messageApi.open({type: 'success', content: '停用成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space>
        <Button onClick={roleAdd}>新增</Button>
        <Button disabled={!selectRowData.id} onClick={roleEdit}>修改</Button>
        <Button disabled={!selectRowData.id || selectRowData.state != 0} onClick={roleDelete}>删除</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={roleOn}>启用</Button>
        <Button disabled={!selectRowData.id || selectRowData.state != 1} onClick={roleOff}>停用</Button>
      </Space>
      <Search search={search} reset={reset}/>
      <Content>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title={isEdit ? '修改角色' : '新增角色'} placement='right' onClose={handleAddCancel} open={addShow}>
        <Form
          form={form}
          {...formItemLayout}
          onFinish={handleAddOk}
        >
          <Add menuChange={menuChange} selectedKeys={selectedKeys}/>
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 18,
            }}
          >
            <Space>
              <Button type='primary' htmlType="submit">确定</Button>
              <Button onClick={handleAddCancel}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </Layout>
  )
}
export default App
