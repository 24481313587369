import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Form, Drawer, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Search from './Search'
import Add from './Add'
import ItemEdit from './ItemEdit'

import tableHeight from '@/utils/tableHeight'

import {
  dictionary_info_getPageList,
  dictionary_info_save,
  dictionary_info_update,
  dictionary_info_updateState,
  dictionary_info_delete
} from '@/services'

const { Content } = Layout;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

const App = () => {

  const [ selectRowData, setSelectRowData ] = useState({})
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "dicCode": "",
    "dicName": "",
    "state": null,
    "pageNo": 1,
    "pageSize": 10
  })
  const [ total, setTotal ] = useState(0)
  const [ addShow, setAddShow ] = useState(false)
  const [ itemEditShow, setItemEditShow ] = useState(false)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ nowTime, setNowTime ] = useState(0)
  const [ childNowTime, setChildNowTime ] = useState(0)
  const [ messageApi, contextHolder ] = message.useMessage();
  const [ form ] = Form.useForm()
  const [ curItem, setCurItem ] = useState({})
  const [ secondItemEditShow, setSecondItemEditShow ] = useState(false);


  const showDetail = (record) => {
    return () => {
      console.log(record)
    }
  }

  const getListData = () => {
    setSelectRowData({})
    dictionary_info_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }

  useEffect(() => {
    getListData()
  }, [params])

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }

  const search = (data) => {
    let newParams = {
      ...data,
      pageNo: 1,
      pageSize: 10
    };
    setParams(newParams)
  }
  const reset = () => {
    setParams({
      "dicCode": "",
      "dicName": "",
      "state": null,
      "pageNo": 1,
      "pageSize": 10
    })
  }

  const dictAdd = () => {
    form.resetFields()
    setIsEdit(false)
    setAddShow(true)
  }
  const dictEdit = () => {
    form.setFieldsValue(selectRowData)
    setIsEdit(true)
    setAddShow(true)
  }
  const handleAddOk = () => {
    form.validateFields().then(values => {
      let fun = isEdit ? dictionary_info_update : dictionary_info_save
      fun(values).then(res => {
        messageApi.open({type: 'success', content: isEdit ? '修改成功' : '新增成功'})
        setAddShow(false)
        pageChange(1)
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    })
  }
  const handleAddCancel = () => {
    setAddShow(false)
  }
  
  const dictOn = () => {
    dictionary_info_updateState({
      "id": selectRowData.id,
	    "state": 1,
    }).then(res => {
      messageApi.open({type: 'success', content: '启用成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const dictOff = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要停用该条字典吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        dictionary_info_updateState({
          "id": selectRowData.id,
          "state": 2
        }).then(res => {
          messageApi.open({type: 'success', content: '停用成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }
  const dictDelete = () => {
    Modal.confirm({
      title: '警告',
      icon: <ExclamationCircleOutlined />,
      content: '数据字典删除后会影响系统使用，请谨慎操作!',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        dictionary_info_delete({
          "id": selectRowData.id,
        }).then(res => {
          messageApi.open({type: 'success', content: '删除成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  const editDictItem = () => {
    setNowTime(Date.now())
    setItemEditShow(true)
  }
  const handleItemEditCancel = () => {
    setItemEditShow(false)
  }

  const editSecondLevel = (data) => {
    console.log(data)
    setCurItem(data)
    setChildNowTime(Date.now())
    setSecondItemEditShow(true)
  }
  const handleSecondItemEditCancel = () => {
    setSecondItemEditShow(false)
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '状态', dataIndex: 'stateName', width: 120},
    {title: '字典编码', dataIndex: 'dicCode', width: 160},
    {title: '字典名称', dataIndex: 'dicName', width: 160, render: (text, record) => {
      return <span onClick={showDetail(record)}>{text}</span>
    }},
    {title: '创建人', dataIndex: 'createUserName', width: 120},
    {title: '创建时间', dataIndex: 'createTime', width: 180},
    {title: '最后修改人', dataIndex: 'updateUserName', width: 120},
    {title: '最后修改时间', dataIndex: 'updateTime', width: 180},
  ]

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space className=''>
        <Button onClick={dictAdd}>新增</Button>
        <Button disabled={!selectRowData.id} onClick={dictEdit}>修改</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={dictDelete}>删除</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={dictOn}>启用</Button>
        <Button disabled={!selectRowData.id || selectRowData.state != 1} onClick={dictOff}>停用</Button>
        <Button disabled={!selectRowData.id} onClick={editDictItem}>编辑字典项</Button>
      </Space>
      <Search search={search} reset={reset}/>
      <Content>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title={isEdit ? '修改字典' : '新增字典'} placement='right' onClose={handleAddCancel} open={addShow}>
        <Form
          form={form}
          {...formItemLayout}
          onFinish={handleAddOk}
        >
          <Add />
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 18,
            }}
          >
            <Space>
              <Button type='primary' htmlType="submit">确定</Button>
              <Button onClick={handleAddCancel}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer title={`编辑字典项 - ${selectRowData.dicName}`}
        placement='bottom'
        onClose={handleItemEditCancel}
        open={itemEditShow}
        height='70%'
      >
       <ItemEdit
          nowTime={nowTime}
          dicCode={selectRowData.dicCode}
          dicId={selectRowData.id}
          editSecondLevel={editSecondLevel}
        />
        <Drawer title={`编辑二级字典项 - ${curItem.itemName}`}
          placement='bottom'
          onClose={handleSecondItemEditCancel}
          open={secondItemEditShow}
          height='70%'
        >
        <ItemEdit
            nowTime={childNowTime}
            dicCode={selectRowData.dicCode}
            dicId={selectRowData.id}
            parentId={curItem.id}
          />
        </Drawer>
      </Drawer>
      
    </Layout>
  )
}
export default App
