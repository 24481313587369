import React, { Component } from 'react'
import { Form, Button, Space } from 'antd'

import './index.scss'

const HzSearch = (props) => {

  const [ form ] = Form.useForm()

  const onFinish = (values) => {
    props.search(values)
  }

  const reset = () => {
    form.resetFields();
    props.reset()
  }

  return (
    <div className='HzSearch'>
      <Form
        form={form}
        name='hz-search-form'
        layout='inline'
        initialValues={props.initialValues}
        onFinish={onFinish}
      >
        {props.children}
        <Form.Item>
          <Space>
            <Button type='primary' htmlType="submit">查询</Button>
            <Button type='text' onClick={reset}>重置</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}
export default HzSearch
