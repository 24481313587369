import { Routes, Route, useLocation, Navigate } from 'react-router-dom'

const AppRouter = (props) => {
  const location = useLocation();
  const { pathname } = location;

  const isLogin = !!localStorage.getItem('token');
  
  const RouteNav = (params) => {
    return (
      params.map(item => {
        // console.log('pathname:', pathname)
        // console.log('item.path:', item.path)
        // console.log('item.auth:', item.auth)
        // console.log('isLogin:', isLogin)
        // console.log(item.path === pathname && item.auth && !isLogin)
        return (
          <Route path={item.path} element={item.path === pathname && item.auth && !isLogin ? <Navigate to='/login' replace /> : item.element} key={item.path} >
            {
              item?.child && RouteNav(item.child)
            }
          </Route>
        )
      })
    )
  }

  return <Routes>{RouteNav(props.routes)}</Routes>
}

export default AppRouter