import React, { Component } from 'react'
import { Layout, Image } from 'antd';
import AppRouter from '@/router/AppRouter'

import './index.scss'
import HzMenu from '@/components/HzMenu'
import HzHeader from '@/components/HzHeader';
import routes from '@/router/routes'

import demoImg from '@/resource/logo.png'

const { Header, Sider, Content } = Layout;

const Home = () => {

  const isLogin = !!localStorage.getItem('token');

  return (
    <div className='Home'>
      <Layout>
        <Sider className='menu-slider'>
          <div className="logo">
            <Image src={demoImg} preview={false}></Image>
          </div>
          <HzMenu />
        </Sider>
        <Layout>
          <Header>
            <HzHeader />
          </Header>
          <Content className='main-content'>
            <AppRouter routes={routes} />
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}

export default Home
