import http from './http'

// 用户登录
export const login = data => http('/cold-chain/admin/adminUser/login', data, 'POST', false);

// 验证码
export const captcha = data => http('/cold-chain/admin/adminUser/captcha', data, 'GET', false);

// 企业管理
export const company_getPageList = data => http('/cold-chain/admin/company/getPageList', data, 'POST', false);
export const company_audit = data => http('/cold-chain/admin/company/audit', data, 'POST', false);
export const company_load = data => http('/cold-chain/admin/company/load', data, 'GET', false);

// 广告管理
export const advertInfo_getPageList = data => http('/cold-chain/admin/advertInfo/getPageList', data, 'POST', false);
export const advertInfo_add = data => http('/cold-chain/admin/advertInfo/add', data, 'POST', false);
export const advertInfo_delete = data => http('/cold-chain/admin/advertInfo/delete', data, 'GET', false);
export const advertInfo_update = data => http('/cold-chain/admin/advertInfo/update', data, 'POST', false);
export const advertInfo_updateState = data => http('/cold-chain/admin/advertInfo/updateState', data, 'POST', false);

// 小程序用户管理 --- 用户管理 > 企业用户
export const user_getUserPageList = data => http('/cold-chain/admin/user/getUserPageList', data, 'POST', false);
export const user_audit = data => http('/cold-chain/admin/user/audit', data, 'POST', false);
export const user_resetPassword = data => http('/cold-chain/admin/user/resetPassword', data, 'GET', false);
export const user_updateUserState = data => http('/cold-chain/admin/user/updateUserState', data, 'POST', false);

// 数据字典
export const dictionary_info_getPageList = data => http('/cold-chain/admin/dictionary/info/getPageList', data, 'POST', false);
export const dictionary_info_save = data => http('/cold-chain/admin/dictionary/info/save', data, 'POST', false);
export const dictionary_info_update = data => http('/cold-chain/admin/dictionary/info/update', data, 'POST', false);
export const dictionary_info_updateState = data => http('/cold-chain/admin/dictionary/info/updateState', data, 'POST', false);
export const dictionary_info_delete = data => http('/cold-chain/admin/dictionary/info/delete', data, 'GET', false);
export const dictionary_item_getItemList = data => http('/cold-chain/admin/dictionary/item/getItemList', data, 'POST', false);
export const dictionary_item_saveItem = data => http('/cold-chain/admin/dictionary/item/saveItem', data, 'POST', false);
export const dictionary_item_updateItem = data => http('/cold-chain/admin/dictionary/item/updateItem', data, 'POST', false);
export const dictionary_item_updateItemState = data => http('/cold-chain/admin/dictionary/item/updateItemState', data, 'POST', false);
export const dictionary_item_deleteItem = data => http('/cold-chain/admin/dictionary/item/deleteItem', data, 'GET', false);
export const dictionary_item_itemSort = data => http('/cold-chain/admin/dictionary/item/itemSort', data, 'GET', false);

// 求职管理
export const applyJob_getPageList = data => http('/cold-chain/admin/applyJob/getPageList', data, 'POST', false);
export const applyJob_close = data => http('/cold-chain/admin/applyJob/close', data, 'GET', false);

// 招聘管理
export const recruit_getPageList = data => http('/cold-chain/admin/recruit/getPageList', data, 'POST', false);
export const recruit_audit = data => http('/cold-chain/admin/recruit/audit', data, 'POST', false);
export const recruit_close = data => http('/cold-chain/admin/recruit/close', data, 'GET', false);

// 角色管理
export const role_assignUser = data => http('/cold-chain/admin/role/assignUser', data, 'POST', false);
export const role_delete = data => http('/cold-chain/admin/role/delete', data, 'GET', false);
export const role_getPageList = data => http('/cold-chain/admin/role/getPageList', data, 'POST', false);
export const role_getUser = data => http('/cold-chain/admin/role/getUser', data, 'GET', false);
export const role_insert = data => http('/cold-chain/admin/role/insert', data, 'POST', false);
export const role_load = data => http('/cold-chain/admin/role/load', data, 'GET', false);
export const role_update = data => http('/cold-chain/admin/role/update', data, 'POST', false);
export const role_updateState = data => http('/cold-chain/admin/role/updateState', data, 'POST', false);

// 人员管理
export const staff_delete = data => http('/cold-chain/admin/staffInfo/delete', data, 'GET', false)
export const staff_getPageList = data => http('/cold-chain/admin/staffInfo/getPageList', data, 'POST', false)
export const staff_save = data => http('/cold-chain/admin/staffInfo/save', data, 'POST', false)
export const staff_update = data => http('/cold-chain/admin/staffInfo/update', data, 'POST', false)
export const staff_updateState = data => http('/cold-chain/admin/staffInfo/updateState', data, 'POST', false)

// 用户管理
export const adminUser_getPageList = data => http('/cold-chain/admin/adminUser/getPageList', data, 'POST', false);
export const adminUser_delete = data => http('/cold-chain/admin/adminUser/delete', data, 'GET', false);
export const adminUser_resetPassword = data => http('/cold-chain/admin/adminUser/resetPassword', data, 'GET', false);
export const adminUser_save = data => http('/cold-chain/admin/adminUser/save', data, 'POST', false);
export const adminUser_update = data => http('/cold-chain/admin/adminUser/update', data, 'POST', false);
export const adminUser_updateState = data => http('/cold-chain/admin/adminUser/updateState', data, 'POST', false);