import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Upload } from 'antd'
import ImgCrop from 'antd-img-crop';
import {
  company_getPageList
} from '@/services'

function Add(props) {

  const [ companyList, setCompanyList ] = useState([])

  const getCompanyList = () => {
    company_getPageList({
      "pageNo": 1,
      "pageSize": 200
    }).then(res => {
      setCompanyList(res.data.info)
    })
  }

  const companyChange = (e) => {
    let {label:companyName, value:companyId} = e
    props.form.setFieldsValue({
      companyId,
      companyName
    })
  }

  useEffect(() => {
    getCompanyList()
  }, [])

  return (
    <div>
      <Form.Item name='id' hidden><Input /></Form.Item>
      <Form.Item name='advertImgUrl' hidden><Input /></Form.Item>
      <Form.Item label='广告编号' name='advertNo'>
        <Input placeholder='系统生成' disabled/>
      </Form.Item>
      <Form.Item label='广告名称' name='advertName' rules={[{ required: true, message: '请输入广告名称' }]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item name='companyName' hidden><Input disabled/></Form.Item>
      <Form.Item name='companyId' label='企业' rules={[{ required: true, message: '请选择企业' }]}>
        <Select
          showSearch
          placeholder='请选择'
          options={companyList}
          fieldNames={{
            label: 'companyName',
            value: 'id'
          }}
          labelInValue
          onChange={companyChange}
        />
      </Form.Item>
      <Form.Item name="advertImgUrl" label='播放图片' rules={[{ required: true, message: '请选择图片' }]}>
        <ImgCrop
          aspect={69/18}
          modalTitle='剪切'
          modalOk='确定'
          modalCancel='取消'
        >
          <Upload
            action={`${process.env.REACT_APP_SERVER_URL}/cold-chain/wxcos/uploadFile`}
            accept='image/*'
            listType="picture-card"
            fileList={props.fileList}
            onChange={props.onUploadChange}
            onPreview={props.onPreview}
          >
            {props.fileList.length < 1 && '+ 上传'}
          </Upload>
        </ImgCrop>
      </Form.Item>
      <Form.Item name="detailImgUrl" label='详情图片' rules={[{ required: true, message: '请选择图片' }]}>
        <Upload
          action={`${process.env.REACT_APP_SERVER_URL}/cold-chain/wxcos/uploadFile`}
          accept='image/*'
          listType="picture-card"
          fileList={props.detailFileList}
          onChange={props.onDetailUploadChange}
          onPreview={props.onDetailPreview}
        >
          {props.detailFileList.length < 1 && '+ 上传'}
        </Upload>
      </Form.Item>
    </div>
  )
}

export default Add