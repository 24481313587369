import React from 'react'
import { Descriptions, Image } from 'antd'

import utils from '@/utils';

const style = {
  avatar: {
    position: 'absolute',
    top: '0px',
    right: '10px',
    width: '80px',
    height: '80px',
    border: '1px solid #EEE',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  pic: {
    display: 'block',
    width: '100%',
    height: '100%'
  },
  imageItem: {
    width: '120px',
    height: '120px'
  }
}

const Detail = (props) => {
  return (
    <div style={{position: 'relative'}}>
      <div style={style.avatar}>
        <img style={style.pic} src={props.avatarUrl} alt="" />
      </div>
      <Descriptions title="基本信息" column={1}>
        <Descriptions.Item label="姓名">{props.fullName || props.nickName}</Descriptions.Item>
        <Descriptions.Item label="性别">{props.gender == 1 ? '男' : '女'}</Descriptions.Item>
        <Descriptions.Item label="身份">{props.userIdentity}</Descriptions.Item>
        <Descriptions.Item label="手机号">{props.userPhone}</Descriptions.Item>
        {/* <Descriptions.Item label="微信号">{props.wechatNo}</Descriptions.Item> */}
        <Descriptions.Item label="邮箱">{props.email}</Descriptions.Item>
        <Descriptions.Item label="出生日期">{props.birthDate}</Descriptions.Item>
        <Descriptions.Item label="参加工作时间">{props.startWorkTime}</Descriptions.Item>
      </Descriptions>
      <Descriptions title="求职信息" column={1}>
        <Descriptions.Item label="求职编号">{props.applyJobNo}</Descriptions.Item>
        <Descriptions.Item label="求职标题">{props.applyJobTitle}</Descriptions.Item>
        <Descriptions.Item label="求职岗位">{props.jobName}</Descriptions.Item>
        <Descriptions.Item label="求职搜索关键词">{props.searchKeywork}</Descriptions.Item>
        <Descriptions.Item label="期望工作地">{
          props.hopeAreaDTOList ? utils.computeAreaValue(props.hopeAreaDTOList, '').desc.map((item, index) => {
            return (
              <div className="addr-item" key={index}>【{item}】</div>
            )
          }) : props.hopeWorkplaceProvinceName == '全部' ? '地点不限' : [
            props.hopeWorkplaceProvinceName,
            props.hopeWorkplaceCityName,
            props.hopeWorkplaceCountyName
          ].filter(item => item !== '全部').join(' ')
        }</Descriptions.Item>
        <Descriptions.Item label="期望薪资">{props.hopeWageName}</Descriptions.Item>
        {/* <Descriptions.Item label="备注">{props.remark}</Descriptions.Item> */}
        {
          props.annexType ? (
            <>
              <Descriptions.Item label="附件简历"></Descriptions.Item>
              {
                props.annexType == 1 ? (
                  <div className="image-list">
                    {
                      props.annexUrl.split(',').map((url, index) => {
                        return (
                          <Image style={style.imageItem} src={url} />
                        )
                      })
                    }
                  </div>
                ) : (
                  <div className="file-info">
                    <a href={props.annexUrl} target='_blank'>{props.annexFileName}</a>
                  </div>
                )
              }
            </>
          ) : (<></>)
        }
      </Descriptions>
    </div>
  )
}

export default Detail
