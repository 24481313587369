import React, { useState } from 'react'
import { UserOutlined, HomeOutlined, CaretDownFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Modal, Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';

import menuData from '../HzMenu/data'
import './index.scss'

function Header() {

  let userData = JSON.parse(localStorage.getItem('userData') || '{}')
  const navigate = useNavigate();

  let breadData = []
  let pathname = window.location.hash.replace('#', '')
  
  const matchBreadName = (data, parentName) => {
    if(pathname === '/') return;
    for(let i = 0; i < data.length; i++) {
      if(pathname === data[i].path) {
        parentName && breadData.push(parentName)
        breadData.push(data[i].label)
        break;
      }
      if(data[i].children) {
        matchBreadName(data[i].children, data[i].label)
      }
    }
  }
  matchBreadName(menuData)

  const logOut = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定要退出登录吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        localStorage.removeItem('token')
        localStorage.removeItem('userData')
        navigate(`/login`)
      }
    });
  }

  const items = [
    {
      key: 1,
      danger: true,
      label: (
        <div onClick={logOut}>退出登录</div>
      )
    }
  ]

  return (
    <div className='hz-header'>
      <div className="bread-area">
      <Breadcrumb separator=">">
        {/* <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item> */}
        <Breadcrumb.Item>汇智冷智慧冷链管理平台</Breadcrumb.Item>
        {
          breadData.map(name => {
            return <Breadcrumb.Item key={name}>{name}</Breadcrumb.Item>
          })
        }
      </Breadcrumb>
      </div>
      <Dropdown menu={{items}} placement="bottomRight" arrow>
        <div className="user-center">
          <Avatar style={{backgroundColor: '#3836A8'}} icon={<UserOutlined />} />
          <div className="user-name">{userData.userName}</div>
          <CaretDownFilled style={{color: '#C0C5D1'}}/>
        </div>
      </Dropdown>
    </div>
  )
}

export default Header