import React from 'react'
import { Descriptions, Image } from 'antd'

const Detail = (props) => {
  return (
    <div>
      <Descriptions title={props.detail.advertName} column={1}>
        <Descriptions.Item label="广告编号">{props.detail.advertNo}</Descriptions.Item>
        <Descriptions.Item label="状态">{['未发布', '播放中', '停播'][props.detail.state]}</Descriptions.Item>
        <Descriptions.Item label="企业">{props.detail.companyName}</Descriptions.Item>
        <Descriptions.Item label="图片">
          <Image
            width={200}
            src={props.detail.advertImgUrl}
          />
        </Descriptions.Item>
        <Descriptions.Item label="详情图片">
          <Image
            width={200}
            src={props.detail.detailImgUrl}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default Detail
