import React from 'react'
import { Form, Input, Select } from 'antd'

import HzSearch from '@/components/HzSearch'

const stateData = [
  { label: '全部', value: null },
  { label: '初始状态', value: 0 },
  { label: '求职中', value: 1 },
  { label: '关闭', value: 2 },
]

const Search = (props) => {

  const search = (values) => {
    props.search(values)
  }

  const reset = () => {
    props.reset()
  }

  return (
    <div className='Search'>
      <HzSearch
        initialValues={{
          "applyJobNo": null,
          "jobCode": null,
          "state": null,
          "userId": null,
        }}
        search={search}
        reset={reset}
      >
        <Form.Item label="状态" name='state'>
          <Select style={{width: 100}} options={stateData} />
        </Form.Item>
        <Form.Item label="求职编号" name='applyJobNo'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="招聘岗位" name='jobCode'>
        <Select style={{width: 160}} placeholder='请选择'/>
        </Form.Item>
        <Form.Item label="求职人" name='userId'>
          <Input placeholder="请输入" />
        </Form.Item>
      </HzSearch>
    </div>
  )
}
export default Search
