import React, { useState, useEffect } from 'react'
import { Form, Input, Select } from 'antd'

function Add(props) {

  return (
    <div>
      <Form.Item name='id' hidden><Input /></Form.Item>
      <Form.Item label='字典项编码' name='itemCode' rules={[{ required: true, message: '请输入字典项编码' }]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='字典项名称' name='itemName' rules={[{ required: true, message: '请输入字典项名称' }]}>
        <Input placeholder='请输入'/>
      </Form.Item>
    </div>
  )
}

export default Add