import {
  dictionary_item_getItemList
} from '@/services'

export default {

  getEnum(dicCode) {
    return new Promise(resolve => {
      dictionary_item_getItemList({
        dicCode
      }).then(res => {
        let items = res.data.info || [];
        resolve(items);
      })
    })
  },

  computeAreaValue(list, splitSymbol = ' ') {
    // console.log('list: ', list)
    let total = list.length;
    let desc = [];
    list.forEach(item => {
      desc.push(item.provinceName == '全部' ? '地点不限' : [
        item.provinceName,
        item.cityName,
        item.countyName
      ].filter(name => name !== '全部').join(splitSymbol))
    })
    return {desc, total}
  }

}