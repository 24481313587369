import React from 'react'
import { Descriptions } from 'antd'

const Detail = (props) => {
  return (
    <div>
      <Descriptions title={props.staffName} column={1}>
        <Descriptions.Item label="人员编号">{props.staffNo}</Descriptions.Item>
        <Descriptions.Item label="性别">{['女', '男'][props.gender]}</Descriptions.Item>
        <Descriptions.Item label="身份证号">{props.idCard}</Descriptions.Item>
        <Descriptions.Item label="现居住地">{props.address}</Descriptions.Item>
        <Descriptions.Item label="手机号">{props.phone}</Descriptions.Item>
        <Descriptions.Item label="出生日期">{props.birthDate}</Descriptions.Item>
        <Descriptions.Item label="学历">{props.staffNo}</Descriptions.Item>
        <Descriptions.Item label="部门">{props.departmentCode}</Descriptions.Item>
        <Descriptions.Item label="公司邮箱">{props.companyEmail}</Descriptions.Item>
        <Descriptions.Item label="紧急联系人">{props.emergencyContact}</Descriptions.Item>
        <Descriptions.Item label="紧急联系方式">{props.emergencyContactPhone}</Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default Detail
