import React from 'react'
import { Layout, Image } from 'antd'

import './index.scss'
import welcomeBg from './welcome-bg.png'

const { Content } = Layout

function App() {
  return (
    <Layout className='list-page welcome-page'>
      <Image className='bg-img' src={welcomeBg} preview={false}></Image>
      <div className="title">欢迎开启汇智冷服务～</div>
      <div className="content">请点击左侧菜单选择您需要的功能</div>
    </Layout>
  )
}

export default App