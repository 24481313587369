import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Drawer, Input, Form, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Search from './Search'
import Detail from '@/pages/CompanyManagement/Detail'

import tableHeight from '@/utils/tableHeight'

import {
  user_getUserPageList,
  user_updateUserState,
  user_resetPassword,
} from '@/services'

// import utils from '@/utils'

const { Content } = Layout;

const App = () => {
  // const Enums = {
  //   GENDER: utils.getEnum('GENDER')
  // }

  const [ drawIsOpen, setDrawIsOpen ] = useState(false)
  const [ selectRowData, setSelectRowData ] = useState({})
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "state": null,
    "userCode": null,
    "userName": null,
    "gender": null,
    "phone": null,
    "pageNo": 1,
    "pageSize": 10,
    "userType": 2,   // 用户类型 1=企业 2=个人
  })
  const [ total, setTotal ] = useState(0)
  const [ messageApi, contextHolder ] = message.useMessage()

  const getListData = () => {
    setSelectRowData({})
    user_getUserPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    })
  }

  useEffect(() => {
    getListData()
  }, [params])

  const showDetail = (record) => {
    return () => {
      console.log(record)
      setDrawIsOpen(true)
    }
  }
  
  const onDrawClose = () => {
    setDrawIsOpen(false)
  }

  const setAudit = (stateValue) => {
    return (e) => {
      
    }
  }

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }
  const search = (data) => {
    let newParams = {
      ...data,
      pageNo: 1,
      pageSize: 10,
      "userType": 2,   // 用户类型 1=企业 2=个人
    };
    setParams(newParams)
  }
  const reset = () => {
    setParams({
      "state": null,
      "userCode": null,
      "userName": null,
      "gender": null,
      "phone": null,
      "pageNo": 1,
      "pageSize": 10,
      "userType": 2,   // 用户类型 1=企业 2=个人
    })
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '状态', dataIndex: 'state', width: 120, render: text => {
      return ['初始', '启用', '停用'][text]
    }},
    {title: '用户编号', dataIndex: 'userCode', width: 120},
    {title: '用户名', dataIndex: 'userName', width: 120},
    {title: '姓名', dataIndex: 'nickName', width: 220},
    {title: '性别', dataIndex: 'gender', width: 120, render: (text) => {
      return text === 0 ? '女' : text == 1 ? '男' : '未设置'
    }},
    {title: '年龄', dataIndex: 'contactName', width: 120},
    {title: '手机号', dataIndex: 'userPhone', width: 180},
    {title: '现居地址', dataIndex: 'address', width: 220},
    {title: '注册时间', dataIndex: 'createTime', width: 180},
  ]

  const setOn = () => {
    user_updateUserState({
      "id": selectRowData.id,
	    "state": 1,
    }).then(res => {
      messageApi.open({type: 'success', content: '启用成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const setOff = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要停用该用户吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        user_updateUserState({
          "id": selectRowData.id,
          "state": 2
        }).then(res => {
          messageApi.open({type: 'success', content: '停用成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }
  const resetPwd = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要重置该用户的密码吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        user_resetPassword({
          "id": selectRowData.id,
        }).then(res => {
          Modal.info({
            title: '温馨提示',
            content: (
              <>
                <div>重置密码成功，新密码为：</div>
                <div style={{
                  fontSize: '32px',
                  fontWeight: 'bold',
                  color: '#322F9F'
                }}>{res.data.info}</div>
              </>
            ),
            okText: '知道了',
          })
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space className=''>
        <Button disabled={!selectRowData.id} onClick={setOn}>启用</Button>
        <Button disabled={!selectRowData.id} onClick={setOff}>停用</Button>
        {/* <Button disabled={!selectRowData.id} onClick={resetPwd}>重置密码</Button> */}
      </Space>
      <Search search={search} reset={reset}/>
      <Content>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title='企业详情' placement='right' onClose={onDrawClose} open={drawIsOpen}>
        <Detail />
      </Drawer>
    </Layout>
  )
}
export default App
