import React, { useState, useEffect } from 'react'
import { Form, Input, Tree } from 'antd'

import treeData from '@/components/HzMenu/data'

function Add(props) {

  const onSelect = (selectedKeys, e) => {
    console.log('selectedKeys', selectedKeys, e)
  }

  const onCheck = (selectedKeys, e) => {
    console.log('selectedKeys', selectedKeys, e)
    props.menuChange(selectedKeys)
  }

  return (
    <div>
      <Form.Item name='id' hidden><Input /></Form.Item>
      <Form.Item label='角色编码' name='roleNo'>
        <Input placeholder='系统自动生成' disabled/>
      </Form.Item>
      <Form.Item label='角色名称' name='roleName' rules={[{ required: true, message: '请输入角色名称' }]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='功能权限' name='menuResourceIdList' rules={[{ required: true, message: '请选择功能权限' }]}>
        <Tree
          checkable
          treeData={treeData}
          fieldNames={{
            title: 'label',
            key: 'key',
            children: 'children'
          }}
          checkedKeys={props.selectedKeys}
          selectedKeys={props.selectedKeys}
          onSelect={onSelect}
          onCheck={onCheck}
        />
      </Form.Item>
    </div>
  )
}

export default Add