import React, { useState, useEffect } from 'react'
import { Form, Input, Select } from 'antd'

import HzSearch from '@/components/HzSearch'
import {
  company_getPageList
} from '@/services'

const stateData = [
  { label: '全部', value: null },
  { label: '初始', value: 0 },
  { label: '启用', value: 1 },
  { label: '停用', value: 2 },
]

const auditStateData = [
  { label: '全部', value: null },
  { label: '待审核', value: 0 },
  { label: '审核通过', value: 1 },
  { label: '审核不过', value: 2 },
]

const Search = (props) => {

  const [ companyList, setCompanyList ] = useState([])

  const getCompanyList = () => {
    company_getPageList({
      "pageNo": 1,
      "pageSize": 200
    }).then(res => {
      setCompanyList(res.data.info)
    })
  }

  useEffect(() => {
    getCompanyList()
  }, [])

  const search = (values) => {
    props.search(values)
  }

  const reset = () => {
    props.reset()
  }

  return (
    <div className='Search'>
      <HzSearch
        initialValues={{
          "state": null,
          "auditState": null,
          "userCode": null,
          "userName": null,
          "companyId": null,
        }}
        search={search}
        reset={reset}
      >
        <Form.Item label="启停状态" name='state'>
          <Select style={{width: 100}} options={stateData} />
        </Form.Item>
        <Form.Item label="审核状态" name='auditState'>
          < Select style={{width: 100}} options={auditStateData} />
        </Form.Item>
        <Form.Item label="企业用户编号" name='userCode'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="企业用户名" name='userName'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="企业名称" name='companyId'>
          <Select
            showSearch
            placeholder='请选择'
            options={companyList}
            style={{width: '200px'}}
            fieldNames={{
              label: 'companyName',
              value: 'id'
            }}
          />
        </Form.Item>
      </HzSearch>
    </div>
  )
}
export default Search
