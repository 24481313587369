import companyIcon from '@/resource/icons/company.png'
import userIcon from '@/resource/icons/user.png'
import inviteIcon from '@/resource/icons/invite.png'
import jobIcon from '@/resource/icons/job.png'
import advIcon from '@/resource/icons/adv.png'
import configIcon from '@/resource/icons/config.png'

import companyOnIcon from '@/resource/icons/company-on.png'
import userOnIcon from '@/resource/icons/user-on.png'
import inviteOnIcon from '@/resource/icons/invite-on.png'
import jobOnIcon from '@/resource/icons/job-on.png'
import advOnIcon from '@/resource/icons/adv-on.png'
import configOnIcon from '@/resource/icons/config-on.png'

export default [
  { id: 1, label: '企业管理', key: 1, pathKey: 'company_management', icon: companyIcon, iconOn: companyOnIcon, type: '', path: '/company', children: null },
  {
    id: 2, label: '用户管理', key: 2, pathKey: 'user_management', icon: userIcon, iconOn: userOnIcon, type: '', path: '/', children: [
      { id: 7, label: '企业用户', key: 7, pathKey: 'company_user', icon: '', type: '', path: '/companyUser', children: null },
      { id: 8, label: '个人用户', key: 8, pathKey: 'personal_user', icon: '', type: '', path: '/personalUser', children: null },
    ]
  },
  { id: 3, label: '招聘管理', key: 3, pathKey: 'invite_management', icon: inviteIcon, iconOn: inviteOnIcon, type: '', path: '/invite', children: null },
  { id: 4, label: '求职管理', key: 4, pathKey: 'job_management', icon: jobIcon, iconOn: jobOnIcon, type: '', path: '/job', children: null },
  { id: 5, label: '广告管理', key: 5, pathKey: 'adv_management', icon: advIcon, iconOn: advOnIcon, type: '', path: '/adv', children: null },
  {
    id: 6, label: '系统管理', key: 6, pathKey: 'system_management', icon: configIcon, iconOn: configOnIcon, type: '', path: '/', children: [
      { id: 9, label: '人员管理', key: 9, pathKey: 'system_member_management', icon: '', type: '', path: '/sysMember', children: null },
      { id: 10, label: '角色管理', key: 10, pathKey: 'system_role_management', icon: '', type: '', path: '/sysRole', children: null },
      { id: 11, label: '用户管理', key: 11, pathKey: 'system_user_management', icon: '', type: '', path: '/sysUser', children: null },
      { id: 12, label: '数据字典', key: 12, pathKey: 'system_dictionary', icon: '', type: '', path: '/dict', children: null },
    ]
  },
]