import React from 'react'
import { Form, Input, Select } from 'antd'

import HzSearch from '@/components/HzSearch'

const stateData = [
  { label: '全部', value: null },
  { label: '创建', value: 0 },
  { label: '启用', value: 1 },
  { label: '停用', value: 2 },
]

const Search = (props) => {

  const search = (values) => {
    props.search(values)
  }
  const reset = () => {
    props.reset()
  }

  return (
    <div className='Search'>
      <HzSearch
        initialValues={{
          "state": null,
          "roleNo": null,
          "roleName": null,
        }}
        search={search}
        reset={reset}
      >
        <Form.Item label="启停状态" name='state'>
          <Select style={{width: 100}} options={stateData} />
        </Form.Item>
        {/* <Form.Item label="角色编号" name='roleNo'>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item label="角色名称" name='roleName'>
          <Input placeholder="请输入" />
        </Form.Item> */}
      </HzSearch>
    </div>
  )
}
export default Search
