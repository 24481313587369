import React, { useState, useEffect } from 'react'
import { Descriptions, Carousel, Image } from 'antd'

import {company_load} from '@/services'

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const Detail = (props) => {

  const [ detail, setDetail ] = useState({})

  useEffect(() => {
    company_load({
      id: props.id
    }).then(res => {
      let detail = res.data.info || {}
      detail.imgUrlArr = detail.imgUrl ? detail.imgUrl.split(',') : [];
      setDetail(detail)
    })
  }, [props.id])

  return (
    <div>
      <Descriptions title={detail.companyName} column={1}>
        <Descriptions.Item label="企业编号">{detail.companyNo}</Descriptions.Item>
        <Descriptions.Item label="审核状态">{['待审核', '审核通过', '审核未通过'][detail.auditState]}</Descriptions.Item>
        <Descriptions.Item label="行业角色">{detail.professionName}</Descriptions.Item>
        <Descriptions.Item label="企业地址">{
          `${detail.provinceName || ''}${detail.cityName || ''}${detail.areaName || ''}${detail.address || ''}`
        }</Descriptions.Item>
        <Descriptions.Item label="联系人">{detail.contactName}</Descriptions.Item>
        <Descriptions.Item label="联系人手机号">{detail.contactPhone}</Descriptions.Item>
        <Descriptions.Item label="业务简介">
          <div dangerouslySetInnerHTML={{__html: detail.businessProfile}}></div>
        </Descriptions.Item>
        <Descriptions.Item label="企业相册"></Descriptions.Item>
      </Descriptions>
      <div className="image-list" style={{display: 'flex', flexWrap: 'wrap'}}>
        {
          detail.imgUrlArr && detail.imgUrlArr.map(item => {
            return <div key={item} style={{
              'margin': '0 10px 10px 0',
              'border': '1px solid #EEE',
              borderRadius: '4px',
              'overflow': 'hidden'
            }}>
              {
                item.indexOf('mp4') > 0 ? (
                  <video width={100} height={100} controls>
                    <source src={item} type="video/mp4"></source>
                  </video>
                ) : (
                  <Image width={100} height={100} src={item}/>
                )
              }
            </div>
          })
        }
      </div>
    </div>
  )
}

export default Detail
