import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Form, Drawer, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import Search from './Search'
import Detail from './Detail'
import Add from './Add'
import utils from '@/utils'
import tableHeight from '@/utils/tableHeight'

import {
  staff_delete,
  staff_getPageList,
  staff_save,
  staff_update,
  staff_updateState,
} from '@/services'

const { Content } = Layout;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const App = () => {

  const [ Enums, setEnums ] = useState({})

  const [ selectRowData, setSelectRowData ] = useState({})
  const [ drawIsOpen, setDrawIsOpen ] = useState(false)
  const [ addIsOpen, setAddIsOpen ] = useState(false)
  const [ listData, setListData ] = useState([])
  const [ params, setParams ] = useState({
    "state": null,
    "staffNo": null,
    "staffName": null,
    "jobCode": null,
    "pageNo": 1,
    "pageSize": 10,
  })
  const [ total, setTotal ] = useState(0)
  const [ addShow, setAddShow ] = useState(false)
  const [ itemEditShow, setItemEditShow ] = useState(false)
  const [ isEdit, setIsEdit ] = useState(false)
  const [ messageApi, contextHolder ] = message.useMessage();
  const [ form ] = Form.useForm()

  const showDetail = (record) => {
    return () => {
      console.log(record)
      setDrawIsOpen(true)
    }
  }

  const initEnums = async () => {
    setEnums({
      GENDER: await utils.getEnum('GENDER'),
      EDU_BG: await utils.getEnum('EDU_BG'),
      DEPARTMENT: await utils.getEnum('DEPARTMENT'),
      JOB_DUTY: await utils.getEnum('JOB_DUTY'),
    })
  }

  const getListData = () => {
    setSelectRowData({})
    staff_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }

  useEffect(() => {
    initEnums();
  }, [])

  useEffect(() => {
    getListData()
  }, [params])

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }

  const search = (data) => {
    let newParams = {
      ...data,
      pageNo: 1,
      pageSize: 10
    };
    setParams(newParams)
  }
  const reset = () => {
    setParams({
      "state": null,
      "staffNo": null,
      "staffName": null,
      "jobCode": null,
      "pageNo": 1,
      "pageSize": 10
    })
  }

  const onDrawClose = () => {
    setDrawIsOpen(false)
  }

  const memberEdit = () => {
    console.log(selectRowData)
    let _data = {...selectRowData}
    _data.birthDate = dayjs(_data.birthDate, 'YYYY-MM-DD');
    _data.gender = `${_data.gender}`
    form.setFieldsValue(_data)
    setIsEdit(true)
    setAddIsOpen(true)
  }

  const memberAdd = () => {
    form.resetFields()
    setIsEdit(false)
    setAddIsOpen(true)
  }
  const onAddClose = () => {
    setAddIsOpen(false)
  }
  const addMemberSubmit = (values) => {
    form.validateFields().then(values => {
      values.birthDate = values.birthDate.format('YYYY-MM-DD')
      let func = isEdit ? staff_update : staff_save
      func(values).then(res => {
        messageApi.open({type: 'success', content: isEdit ? '修改成功' : '新增成功'})
        setAddIsOpen(false)
        pageChange(1)
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    })
  }

  const getEnumNameByCode = (list = [], code) => {
    for(let i = 0; i < list.length; i++) {
      if(list[i].itemCode == code) {
        return list[i].itemName
      }
    }
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '状态', dataIndex: 'state', width: 120, render: (text) => {
      return ['新创建', '启用', '停用'][text]
    }},
    {title: '人员编号', dataIndex: 'staffNo', width: 140},
    {title: '姓名', dataIndex: 'staffName', width: 120, render: (text, record) => {
      return <span className='link' onClick={showDetail(record)}>{text}</span>
    }},
    {title: '性别', dataIndex: 'gender', width: 120, render: (text) => {
      return getEnumNameByCode(Enums.GENDER, text)
    }},
    {title: '部门', dataIndex: 'departmentCode', width: 120, render: (text) => {
      return getEnumNameByCode(Enums.DEPARTMENT, text)
    }},
    {title: '学历', dataIndex: 'qualificationsCode', width: 180, render: (text) => {
      return getEnumNameByCode(Enums.EDU_BG, text)
    }},
    {title: '职位', dataIndex: 'jobCode', width: 220, render: (text) => {
      return getEnumNameByCode(Enums.JOB_DUTY, text)
    }},
    {title: '创建人', dataIndex: 'creatorUserName', width: 120},
    {title: '创建时间', dataIndex: 'creatorTime', width: 180},
    {title: '最后修改人', dataIndex: 'updateUserName', width: 120},
    {title: '最后修改时间', dataIndex: 'updateTime', width: 180},
  ]

  const memberDelete = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要删除该人员吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        staff_delete({
          "id": selectRowData.id,
        }).then(res => {
          messageApi.open({type: 'success', content: '删除成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }
  const memberOn = () => {
    staff_updateState({
      "id": selectRowData.id,
      "state": 1
    }).then(res => {
      messageApi.open({type: 'success', content: '启用成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const memberOff = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要停用该人员吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        staff_updateState({
          "id": selectRowData.id,
          "state": 2
        }).then(res => {
          messageApi.open({type: 'success', content: '停用成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space className=''>
        <Button onClick={memberAdd}>新增</Button>
        <Button disabled={!selectRowData.id} onClick={memberEdit}>修改</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={memberDelete}>删除</Button>
        <Button disabled={!selectRowData.id || selectRowData.state == 1} onClick={memberOn}>启用</Button>
        <Button disabled={!selectRowData.id || selectRowData.state != 1} onClick={memberOff}>停用</Button>
      </Space>
      <Search search={search} reset={reset} enums={Enums}/>
      <Content>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title='人员详情' placement='right' onClose={onDrawClose} open={drawIsOpen}>
        <Detail {...selectRowData}/>
      </Drawer>
      <Drawer title='人员新增' placement='right' onClose={onAddClose} open={addIsOpen}>
        <Form
          form={form}
          {...formItemLayout}
          onFinish={addMemberSubmit}
        >
          <Add Enums={Enums} form={form}/>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Space>
              <Button type='primary' htmlType="submit">确定</Button>
              <Button>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </Drawer>
    </Layout>
  )
}
export default App
