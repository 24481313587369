import React, { useState, useEffect } from 'react'
import { Button, Table, Space, Form, Input, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';

import AddItem from './AddItem'
// import AddItem from './MultiAddItem'
import './ItemEdit.scss'
import {
  dictionary_item_getItemList,
  dictionary_item_saveItem,
  dictionary_item_updateItem,
  dictionary_item_updateItemState,
  dictionary_item_deleteItem,
  dictionary_item_itemSort
} from '@/services'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

function ItemEdit(props) {

  const [ listData, setListData ] = useState([])
  const [ selectRowData, setSelectRowData ] = useState({})
  const [ isEdit, setIsEdit ] = useState(false)
  const [ isAddOrEdit, setIsAddOrEdit ] = useState(false)
  const [ editName, setEditName ] = useState('')
  const [ tableBodyHeight, settableBodyHeight ] = useState('')
  const [ messageApi, contextHolder ] = message.useMessage()

  const [ form ] = Form.useForm()
  

  const getListData = () => {
    if(!props.dicCode) return;
    dictionary_item_getItemList({
      dicCode: props.dicCode,
      parentId: props.parentId || null,
      pageNo: 1,
      pageSize: 100
    }).then(res => {
      setListData(res.data.info || [])
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }

  // 临时方法 - 排序
  // useEffect(() => {
  //   sort()
  // }, [listData])

  useEffect(() => {
    setSelectRowData({})
    form.setFieldsValue({
      dicCode: props.dicCode,
      dicId: props.dicId,
    })
    setIsAddOrEdit(false)
    getListData()

    let ele = document.getElementsByClassName('ant-table-body')[1]
    if(ele.clientHeight > 0) {
      settableBodyHeight(ele.clientHeight)
    }
  }, [props.nowTime])

  const handleAddOk = () => {
    form.validateFields().then(values => {
      let data = props.parentId ? {
        ...values,
        parentId: props.parentId
      } : values
      let fun = isEdit ? dictionary_item_updateItem : dictionary_item_saveItem
      fun(data).then(res => {
        messageApi.open({type: 'success', content: isEdit ? '修改成功' : '新增成功'})
        setIsAddOrEdit(false)
        getListData()
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    })
  }
  const handleAddCancel = () => {
    setIsAddOrEdit(false)
  }

  const addItem = () => {
    setIsEdit(false)
    setSelectRowData({})
    form.resetFields();
    form.setFieldsValue({
      dicCode: props.dicCode,
      dicId: props.dicId,
    })
    setIsAddOrEdit(true)
  }

  const editItem = () => {
    setIsEdit(true)
    setEditName(selectRowData.itemName)
    form.setFieldsValue(selectRowData)
    setIsAddOrEdit(true)
  }
  const deleteItem = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要删除该条字典项吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        dictionary_item_deleteItem({
          "id": selectRowData.id,
        }).then(res => {
          messageApi.open({type: 'success', content: '删除成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }
  const setOnItem = () => {
    dictionary_item_updateItemState({
      "id": selectRowData.id,
      "state": 1
    }).then(res => {
      messageApi.open({type: 'success', content: '启用成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const setOffItem = () => {
    Modal.confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确认要停用该条字典项吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        dictionary_item_updateItemState({
          "id": selectRowData.id,
          "state": 0
        }).then(res => {
          messageApi.open({type: 'success', content: '停用成功'})
          getListData()
        }).catch(err => {
          messageApi.open({type: 'error', content: err.data.rspDesc})
        })
      }
    });
  }

  const moveItem = (params) => {
    dictionary_item_itemSort(params).then(res => {
      messageApi.open({type: 'success', content: '操作成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }

  const moveUp = (curData, index) => {
    return () => {
      let parmas = {
        upId: curData.id,
        downId: listData[index - 1].id
      }
      moveItem(parmas)
    }
  }

  const moveDown = (curData, index) => {
    return () => {
      let parmas = {
        downId: curData.id,
        upId: listData[index + 1].id
      }
      moveItem(parmas)
    }
  }

  
  const columns = [
    {title: '状态', dataIndex: 'stateName', width: 100},
    {title: '字典项编码', dataIndex: 'itemCode', width: 120},
    {title: '字典项名称', dataIndex: 'itemName', width: 160},
    {title: '操作',  width: 160, render: (text, record, index) => {
      return (
        <Space>
          {
            index < listData.length - 1 ? (
              <Button type='link' onClick={moveDown(record, index)}>下移</Button>
            ) : (<></>)
          }
          {
            index > 0 ? (
              <Button type='link' onClick={moveUp(record, index)}>上移</Button>
            ) : (<></>)
          }
        </Space>
      )
    }},
  ]

  const editSecondLevel = () => {
    props.editSecondLevel(selectRowData)
  }


  // 临时方法 - 排序
  // const sort = () => {
  //   for(let i = 0; i < listData.length - 1; i++) {
  //     let cur = listData[i];
  //     let next = listData[i + 1];
  //     let curNum = Number(cur.itemCode.substr(2));
  //     let nextNum = Number(next.itemCode.substr(2));
  //     if(curNum > nextNum) {
  //       let D = new Date();
  //       console.log(`移动【${curNum}】-->【${nextNum}】${D.getHours()}:${D.getMinutes()}:${D.getSeconds()}`)
  //       moveDown(cur, i)();
  //       break;
  //     }
  //   }
  // }

  // 临时方法 - 批量新增二级字典
  // const [ multiData, setMultiData ] = useState([])
  // const setZero = (num) => {
  //   return num < 10 ? `0${num}` : num
  // }
  // const handleMultiAddOk = () => {
  //   form.validateFields().then(values => {
  //     console.log(values)
  //     let preCode = values.preCode;
  //     let nameList = values.nameList.split(' ');
  //     let data = [];
  //     nameList.forEach((item, index) => {
  //       data.push({
  //         itemCode: `${preCode}${setZero(index + 1)}`,
  //         itemName: item,
  //         dicCode: values.dicCode,
  //         dicId: values.dicId,
  //         parentId: props.parentId
  //       })
  //     })
  //     setMultiData(data)
  //   })
  // }
  // const multiSubmit = () => {
  //   let delay = 200;
  //   multiData.forEach((item, index) => {
  //     setTimeout(() => {
  //       dictionary_item_saveItem(item).then(res => {
  //         console.log(`批量新增成功：${item.itemName}`)
  //       }).catch(err => {
  //         console.error(`批量新增失败：${item.itemName}`, err)
  //       })
  //     }, delay * (multiData.length - 1 - index))
  //   })
  //   setTimeout(()=> {
  //     getListData()
  //   }, delay * multiData.length)
  // }

  return (
    <div className='dict-edit-item'>
      {contextHolder}
      <div className="btn-area">
        <Space>
          <Button onClick={addItem}>新增</Button>
          <Button disabled={!selectRowData.id} onClick={editItem}>修改</Button>
          <Button disabled={!selectRowData.id} onClick={deleteItem}>删除</Button>
          <Button disabled={!selectRowData.id} onClick={setOnItem}>启用</Button>
          <Button disabled={!selectRowData.id} onClick={setOffItem}>停用</Button>
          {
            props.parentId ? (<></>) : (
              <Button disabled={!selectRowData.id} onClick={editSecondLevel}>编辑二级字典</Button>
            )
          }
        </Space>
      </div>
      <div className="container">
        <div className="list-page list-area">
          <Table
            dataSource={listData}
            bordered
            rowKey='id'
            scroll={{ y: tableBodyHeight }}
            pagination={false}
            columns={columns}
            rowClassName={(record) => {
              return selectRowData.id === record.id ? 'selected' : ''
            }}
            onRow={(record) => ({
              onClick: () => {
                setSelectRowData(record);
              },
            })}
          />
        </div>
        <div className="form-aera"
          style={{display: isAddOrEdit ? 'block' : 'none'}}
        >
          <div className="form-title">
            {isEdit ? `修改字典项-${editName}` : '新增字典项'}
          </div>
          <Form
            form={form}
            {...formItemLayout}
            onFinish={handleAddOk}
            // onFinish={handleMultiAddOk}
          >
            <AddItem />
            <Form.Item name={'dicId'} hidden><Input /></Form.Item>
            <Form.Item name={'dicCode'} hidden><Input /></Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 18,
              }}
            >
              <Space>
                <Button type='primary' htmlType="submit">确定</Button>
                <Button onClick={handleAddCancel}>取消</Button>
              </Space>
            </Form.Item>
          </Form>
          {/* <div>总数：{multiData.length}</div>
          {
            multiData.map(item => {
              return (<div key={item.itemCode}>{item.itemCode}:{item.itemName}</div>)
            })
          }
          <Button type='primary' onClick={multiSubmit}>批量提交</Button> */}
        </div>
      </div>
    </div>
  )
}

export default ItemEdit