import axios from 'axios';

/**
 * 封装通用Axios请求方法。
 * @param {String} url 接口api地址
 * @param {Object} data 参数
 * @param {String} method 方法：GET 或者 POST
 * @param {Boolean} isParams POST方法是否使用params传参
 * @returns new Promise
 */

// 可以在此对引入的axios进行对应的改造or功能升级……

const http = (url, data, method, isParams) => {

  // 获取用户数据
  const token = localStorage.getItem('token') || '';

  // 拼接header数据
  const headers = {
    'Content-Type': 'application/json;charset=UTF-8',
    'token': token
  };

  // 返回 Promise
  return new Promise((resolve, reject) => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}${url}`,
      method,
      headers,
      [isParams || method === 'GET' ? 'params' : 'data']: data,
    }).then(res => {
      if(res.data.rspCode === 0) {
        resolve(res);
      } else {
        // Message.error(`${res.data.rspDesc || res.data.info}`);
        console.warn(`ServerError: ${url} >> Code: ${res.data.rspCode} ${res.data.rspDesc || res.data.info}`);
        reject(res);
      }
    }).catch(err => {
      reject(err);
    });
  })
  
}

export default http;
