import React, { useState, useEffect } from 'react'
import { Menu, Image, Popover } from 'antd'
import { useNavigate } from 'react-router-dom'

import menuData from './data'
import './index.scss'

const HzMenu = () => {

  const [ roleIdList, setRoleIdList ] = useState([])

  useEffect(() => {
    let roleList = JSON.parse(localStorage.getItem('userData')).menuResourceIdList || []
    setRoleIdList(roleList)
  }, [])

  let defaultSelectedKeys = [];

  let pathname = window.location.pathname;
  for(let i = 0; i < menuData.length; i++) {
    if(pathname === '/') break;
    if(pathname === menuData[i].path) {
      defaultSelectedKeys = ([menuData[i].key])
      break;
    }
    if(menuData[i].children) {
      for(let j = 0; j < menuData[i].children.length; j++) {
        if(pathname === menuData[i].children[j].path) {
          defaultSelectedKeys = ([menuData[i].key, menuData[i].children[j].key])
          break;
        }
      }
    }
  }

  // console.log('匹配路径', defaultSelectedKeys)

  const navigate = useNavigate();
  const handleMenuClick = (e) => {
    // console.log('menu click', e);
    let path = e.item.props.path;
    if(path === '/') return;
    navigate(`${path}`);
  }
  const childMenuClick = (child) => {
    return (e) => {
      navigate(`${child.path}`);
    }
  }

  return (
    <div className='hz-menu'>
      <Menu
        defaultSelectedKeys={defaultSelectedKeys}
        onClick={handleMenuClick}
      >
        {
          menuData.map(item => {
            if(roleIdList.length === 0 || roleIdList.indexOf(item.id) > -1) {
              if(item.children) {
                const content = (
                  <div className='child-menu'>
                    {
                      item.children.map(child => {
                        if(roleIdList.length === 0 || roleIdList.indexOf(child.id) > -1) {
                          return (
                            <div className='child-menu-item' key={child.key} onClick={childMenuClick(child)}>{child.label}</div>
                          )
                        }
                      })
                    }
                  </div>
                )
                return (
                  <Menu.Item key={item.key} path={item.path}>
                    <Popover content={content} placement='rightTop'>
                      <div className="menu-icon">
                        <Image className='icon' src={item.icon} preview={false}></Image>
                      </div>
                      <div className="menu-icon-on">
                        <Image className='icon-on' src={item.iconOn} preview={false}></Image>
                      </div>
                      <div className="label">{item.label}</div>
                    </Popover>
                  </Menu.Item>
                )
              }
              return (
                <Menu.Item key={item.key} path={item.path}>
                  <div className="menu-icon">
                    <Image className='icon' src={item.icon} preview={false}></Image>
                  </div>
                  <div className="menu-icon-on">
                    <Image className='icon-on' src={item.iconOn} preview={false}></Image>
                  </div>
                  <div className="label">{item.label}</div>
                </Menu.Item>
              )
            }
          })
        }
      </Menu>
    </div>
  )
}

export default HzMenu
