import React, { useState, useEffect } from 'react'
import { Layout, Button, Table, Space, Drawer, Modal, Form, Input, message } from 'antd'

import Search from './Search'
import Detail from './Detail'

import tableHeight from '@/utils/tableHeight'

import {
  company_getPageList,
  company_audit,
} from '@/services'

const { Content } = Layout;

const App = () => {

  const [ messageApi, contextHolder ] = message.useMessage()
  const [ drawIsOpen, setDrawIsOpen ] = useState(false)
  const [ auditReasonShow, setAuditReasonShow ] = useState(false)
  const [ selectRowData, setSelectRowData ] = useState({})
  const [ listData, setListData ] = useState([])
  const [ pageNo, setPageNo ] = useState(1)
  const [ params, setParams ] = useState({
    "auditState": null,
    "companyName": "",
    "companyNo": "",
    "contactName": "",
    "contactPhone": "",
    "pageNo": 1,
    "pageSize": 10
  })
  const [ total, setTotal ] = useState(0)
  const [ form ] = Form.useForm()

  const showDetail = (record) => {
    return () => {
      console.log(record)
      setDrawIsOpen(true)
    }
  }

  const onDrawClose = () => {
    setDrawIsOpen(false)
  }
  
  const getListData = () => {
    setSelectRowData({})
    company_getPageList(params).then(res => {
      setListData(res.data.info || [])
      setTotal(res.data.total)
    })
  }

  const pageChange = (pageNo) => {
    let newParams = {
      ...params,
      pageNo
    }
    setParams(newParams)
  }

  useEffect(() => {
    getListData()
  }, [params])

  const search = (data) => {
    let newParams = {
      ...data,
      "pageNo": 1,
      "pageSize": 10
    }
    setParams(newParams)
  }

  const reset = () => {
    setParams({
      "auditState": null,
      "companyName": "",
      "companyNo": "",
      "contactName": "",
      "contactPhone": "",
      "pageNo": 1,
      "pageSize": 10
    })
  }

  const columns = [
    {title: '序号', width: 80, render: (text, record, index) => {return index + 1}},
    {title: '审核状态', dataIndex: 'auditState', width: 120, render: (text) => {
      return ['待审核', '审核通过', '审核不过'][text]
    }},
    {title: '企业编号', dataIndex: 'companyNo', width: 160},
    {title: '企业名称', dataIndex: 'companyName', width: 220, render: (text, record) => {
      return <span className='link' onClick={showDetail(record)}>{text}</span>
    }},
    {title: '行业', dataIndex: 'professionName', width: 120},
    {title: '企业联系人', dataIndex: 'contactName', width: 120},
    {title: '企业联系人手机号', dataIndex: 'contactPhone', width: 180},
    {title: '企业地址', dataIndex: 'areaName', width: 220, render: (text, record) => {
      return <span>{`${record.provinceName || ''}${record.cityName || ''}${record.areaName || ''}${record.address || ''}`}</span>
    }},
    {title: '审核人', dataIndex: 'auditName', width: 120},
    {title: '审核时间', dataIndex: 'updateTime', width: 180},
    {title: '审核不过原因', dataIndex: 'auditNopassReason', width: 240},
    {title: '创建时间', dataIndex: 'createTime', width: 180},
  ]

  const setAuditPass = () => {
    company_audit({
      companyId: selectRowData.id,
      auditState: 1,
      nopassReason: '',
    }).then(res => {
      messageApi.open({type: 'success', content: '设置成功'})
      getListData()
    }).catch(err => {
      messageApi.open({type: 'error', content: err.data.rspDesc})
    })
  }
  const setAuditNoPass = () => {
    form.resetFields()
    setAuditReasonShow(true)
  }
  const auditReasonOK = () => {
    form.validateFields().then(values => {
      console.log(values)
      company_audit({
        ...values,
        auditState: 2,
        companyId: selectRowData.id
      }).then(res => {
        messageApi.open({type: 'success', content: '设置成功'})
        setAuditReasonShow(false)
        getListData()
      }).catch(err => {
        messageApi.open({type: 'error', content: err.data.rspDesc})
      })
    })
  }
  const auditReasonCancel = () => {
    setAuditReasonShow(false)
  }

  return (
    <Layout className='list-page'>
      {contextHolder}
      <Space className=''>
        <Button disabled={!selectRowData.id || selectRowData.auditState == 1} onClick={setAuditPass}>审核通过</Button>
        <Button disabled={!selectRowData.id} onClick={setAuditNoPass}>审核不过</Button>
      </Space>
      <Search search={search} reset={reset}/>
      <Content>
        <Table
          dataSource={listData}
          bordered
          rowKey='id'
          scroll={{ y: tableHeight()}}
          columns={columns}
          pagination={{
            current: params.pageNo,
            pageSize: 10,
            showSizeChanger: false,
            total: total,
            onChange: pageChange
          }}
          rowClassName={(record) => {
            return selectRowData.id === record.id ? 'selected' : ''
          }}
          onRow={(record) => ({
            onClick: () => {
              setSelectRowData(record);
            },
          })}
        />
      </Content>
      <Drawer title='企业详情' placement='right' onClose={onDrawClose} open={drawIsOpen}>
        <Detail id={selectRowData.id}/>
      </Drawer>
      <Modal title="审核不过" open={auditReasonShow} onOk={auditReasonOK} onCancel={auditReasonCancel}>
        <Form
          form={form}
        >
          <Form.Item name={'nopassReason'} label='原因' rules={[{required: true, message: '请输入审核不过原因'}]}>
            <Input placeholder='审核不过原因'/>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  )
}
export default App
