import React from 'react'
import { Form, Input, Select, DatePicker } from 'antd'

const enumFieldNames = {
  label: 'itemName',
  value: 'itemCode'
}

function Add(props) {

  const dateChange = (date, dateStr) => {
    console.log(date, dateStr)
    // props.form.setFieldsValue({
    //   'birthDate': dateStr
    // })
  }

  return (
    <div>
      {/* {JSON.stringify(props)} */}
      <Form.Item name={'id'} hidden><Input /></Form.Item>
      <Form.Item label='人员编号' name={'staffNo'}>
        <Input disabled placeholder='系统自动生成'/>
      </Form.Item>
      <Form.Item label='姓名' name={'staffName'} rules={[{required: true, message: '请输入姓名!'}]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='性别' name={'gender'} rules={[{required: true, message: '请选择性别!'}]}>
        <Select placeholder='请选择' options={props.Enums.GENDER} fieldNames={enumFieldNames}/>
      </Form.Item>
      <Form.Item label='身份证号' name={'idCard'} rules={[{required: true, message: '请输入身份证号!'}]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='现居住地' name={'address'} rules={[{required: true, message: '请输入现居住地!'}]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='手机号' name={'phone'} rules={[{required: true, message: '请输入手机号!'}]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='出生日期' name={'birthDate'} rules={[{required: true, message: '请输入出生日期!'}]}>
        <DatePicker format='YYYY-MM-DD' onChange={dateChange}/>
      </Form.Item>
      <Form.Item label='学历' name={'qualificationsCode'} rules={[{required: true, message: '请选择学历!'}]}>
        <Select placeholder='请选择' options={props.Enums.EDU_BG} fieldNames={enumFieldNames}/>
      </Form.Item>
      <Form.Item label='部门' name={'departmentCode'} rules={[{required: true, message: '请选择部门!'}]}>
        <Select placeholder='请选择' options={props.Enums.DEPARTMENT} fieldNames={enumFieldNames}/>
      </Form.Item>
      <Form.Item label='职位' name={'jobCode'} rules={[{required: true, message: '请选择职位!'}]}>
        <Select placeholder='请选择' options={props.Enums.JOB_DUTY} fieldNames={enumFieldNames}/>
      </Form.Item>
      <Form.Item label='公司邮箱' name={'companyEmail'} rules={[{required: true, message: '请输入公司邮箱!'}]}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='紧急联系人' name={'emergencyContact'}>
        <Input placeholder='请输入'/>
      </Form.Item>
      <Form.Item label='紧急联系方式' name={'emergencyContactPhone'}>
        <Input placeholder='请输入'/>
      </Form.Item>
    </div>
  )
}

export default Add