import CompanyManagementList from '@/pages/CompanyManagement'
import CompanyUser from '@/pages/CompanyUser'
import PersonalUser from '@/pages/PersonalUser'
import AdvManagement from '@/pages/AdvManagement'
import InviteManagement from '@/pages/InviteManagement'
import JobManagement from '@/pages/JobManagement'
import SysDict from '@/pages/SysDict'
import SysMember from '@/pages/SysMember'
import SysRole from '@/pages/SysRole'
import SysUser from '@/pages/SysUser'
import Welcome from '@/pages/Welcome'

export default [
  {
    id: 1,
    path: '/company',
    element: <CompanyManagementList />,
    auth: true,
  },
  {
    id: 7,
    path: '/companyUser',
    element: <CompanyUser />,
    auth: true,
  },
  {
    id: 8,
    path: '/personalUser',
    element: <PersonalUser />,
    auth: true,
  },
  {
    id: 5,
    path: '/adv',
    element: <AdvManagement />,
    auth: true,
  },
  {
    id: 3,
    path: '/invite',
    element: <InviteManagement />,
    auth: true,
  },
  {
    id: 4,
    path: '/job',
    element: <JobManagement />,
    auth: true,
  },
  {
    id: 12,
    path: '/dict',
    element: <SysDict />,
    auth: true,
  },
  {
    id: 9,
    path: '/sysMember',
    element: <SysMember />,
    auth: true,
  },
  {
    id: 11,
    path: '/sysRole',
    element: <SysRole />,
    auth: true,
  },
  {
    id: 10,
    path: '/sysUser',
    element: <SysUser />,
    auth: true,
  },
  {
    id: '*',
    path: '/*',
    element: <Welcome />,
    auth: true,
  },
]